import axios from "axios";

const API_KEY = process.env.NEXT_PUBLIC_TRANSLATE_API_KEY;

/**
 * Translate text using Google Translate API
 * @param {string} text - Text to translate
 * @param {string} targetLanguage - Target language code (e.g., 'es' for Spanish)
 * @returns {Promise<string>} - Translated text
 */
export async function translateText(text, targetLanguage) {
  try {
    const response = await axios.post(
      `https://translation.googleapis.com/language/translate/v2?key=${API_KEY}`,
      {
        q: text,
        target: targetLanguage,
        format: "text",
      }
    );

    return response.data.data.translations[0].translatedText;
  } catch (error) {
    console.error(
      "Error during translation:",
      error?.response?.data || error.message
    );
    throw new Error("Failed to translate text.");
  }
}
