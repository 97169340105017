"use client";
import { useState, useEffect } from "react";
import { translateText } from "../utils/translate";

const useTranslator = (texts) => {
  const [translator, setTranslator] = useState({});
  const [language, setLanguage] = useState("en"); // Default language

  useEffect(() => {
    if (typeof window !== "undefined") {
      setLanguage(localStorage.getItem("lang") || "bn");
    }

    // ✅ Polling mechanism to detect `localStorage` changes
    const interval = setInterval(() => {
      const updatedLanguage = localStorage.getItem("lang") || "bn";
      setLanguage((prevLang) =>
        prevLang !== updatedLanguage ? updatedLanguage : prevLang
      );
    }, 1000); // Runs every second

    return () => {
      clearInterval(interval);
    };
  }, []); // ✅ Runs only once on mount, but updates via interval

  // ✅ Re-run translations when `language` updates
  useEffect(() => {
    if (!texts || Object.keys(texts).length === 0) return;

    const translateAllTexts = async () => {
      const keys = Object.keys(texts);
      const translations = await Promise.all(
        keys.map((key) => translateText(texts[key], language))
      );

      const newTranslations = keys.reduce((acc, key, index) => {
        acc[key] = translations[index];
        return acc;
      }, {});

      setTranslator(newTranslations);
    };

    translateAllTexts();
  }, [texts, language]); // ✅ Re-run when `language` changes

  return translator;
};

export default useTranslator;
